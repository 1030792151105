import React from 'react'

export default function App() {
  return (
    <React.Fragment>
   <div class="maintain-wrap">
		<div class="maintain-content">
			<h2>Watch this space!</h2>
			<p>We’re currently improving our site and working to get the site back for</p>
			<div class="time-space">
				<p>Back Soon</p>
				{/* <span>GMT</span> */}
			</div>
			<p>Apologies for any inconvenience this may cause.</p>
		</div>	
	</div>
    </React.Fragment>
  )
}
